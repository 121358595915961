import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { getSlideSetVersions, SlideSetVersion } from '../api/slideSetVersion';
import { SlideSetVersionState } from '../api/slideSet';

type SlideSetVersionsContextValue = {
  versions: SlideSetVersion[];
  refreshVersions: () => Promise<void>;
  getVersionIndex: (id: number) => number;
};

const defaultSlideSetVersionsContextValue: SlideSetVersionsContextValue = {
  versions: [],
  refreshVersions: () => Promise.resolve(),
  getVersionIndex: () => 0,
};

const stateOrder = [
  SlideSetVersionState.PUBLISHED,
  SlideSetVersionState.REVIEW,
  SlideSetVersionState.DRAFT,
  SlideSetVersionState.ARCHIVED,
];

const SlideSetVersionContext = createContext<SlideSetVersionsContextValue>(defaultSlideSetVersionsContextValue);

export default SlideSetVersionContext;

export const VersionContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { setId, nodeId, moduleId } = useParams();

  const [versions, setVersions] = useState<SlideSetVersion[]>([]);
  const [sortedIdsByCreation, setSortedIdsByCreation] = useState<number[]>([]);

  const fetchVersions = useCallback(async () => {
    if (!moduleId || !nodeId || !setId) return;
    const fetchedVersions = await getSlideSetVersions(moduleId, nodeId, setId);

    const sortedVersions = fetchedVersions.sort((x, y) => {
      if (x.updatedAt === y.updatedAt) return stateOrder.indexOf(x.state) - stateOrder.indexOf(y.state);
      return new Date(x.updatedAt) < new Date(y.updatedAt) ? 1 : -1;
    });

    setVersions(sortedVersions);

    const sortedVersionsByCreationDate = [...sortedVersions].sort((x, y) =>
      new Date(x.createdAt) > new Date(y.createdAt) ? 1 : -1,
    );

    setSortedIdsByCreation(sortedVersionsByCreationDate.map(version => version.id));
  }, [moduleId, nodeId, setId]);

  const getVersionIndex = useCallback((id: number) => sortedIdsByCreation.indexOf(id) + 1, [sortedIdsByCreation]);

  useEffect(() => {
    fetchVersions();
  }, [fetchVersions]);

  const versionsContextValue = useMemo(
    () => ({
      versions,
      refreshVersions: fetchVersions,
      getVersionIndex,
    }),
    [versions, fetchVersions, getVersionIndex],
  );

  return <SlideSetVersionContext.Provider value={versionsContextValue}>{children}</SlideSetVersionContext.Provider>;
};
