import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { Typography, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { tss } from 'tss-react/mui';

import placeholderImage from '../../../../assets/images/placeholder.webp';
import { SlideGroup, deleteSlide, deleteSlideGroup } from '../../../api/slideSet';
import DragIcon from './drag-icon';
import SlidingConfirmation from '../../../components/sliding-confirmation';

const useStyles = tss.create(({ theme }) => ({
  slide: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    height: '6rem',
    gap: theme.spacing(1),
    '& img': {
      height: '100%',
    },
  },
  hide: {
    height: 0,
    opacity: 0,
  },
  textContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowX: 'hidden',
  },
  textArea: {
    height: '100%',
    width: '100%',
    minWidth: '20rem',
    margin: 0,
    border: '1px solid #ABABAB',
    backgroundColor: '#F0F0F0',
    paddingLeft: '0.5rem',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
    width: '7rem',
    minWidth: '7rem',
    flexGrow: 0,
  },
}));

type SlideDisplayProps = {
  slide: SlideGroup['slides'][number];
  indexString: string;
  isDragging: boolean;
  group: SlideGroup;
  refetchData: () => void;
};

function SlideDisplay({
  group,
  slide,
  indexString,
  isDragging,
  dragRef,
  refetchData,
}: SlideDisplayProps & { dragRef: React.ForwardedRef<HTMLDivElement> }) {
  const { setId, nodeId, moduleId, versionId } = useParams();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const handleEditSlideClick = () => {
    navigate(`groups/${group.id}/slides/${slide.id}`);
  };

  const handleDelete = async () => {
    if (!moduleId || !nodeId || !setId || !versionId) return;

    if (group.type === 'SINGLE') {
      await deleteSlideGroup(moduleId, nodeId, setId, versionId, group.id);
    } else {
      await deleteSlide(moduleId, nodeId, setId, versionId, group.id, slide.id);
    }

    refetchData();
  };

  return (
    <div data-testid={`slide-${slide.id}`} className={cx({ [classes.slide]: true, [classes.hide]: isDragging })}>
      <Typography>{indexString}</Typography>
      <img alt={slide.thumbnail ? 'thumbnail' : 'placeholder'} src={slide.thumbnail || placeholderImage} />
      <div className={classes.textContainer}>
        <div className={classes.textArea} dangerouslySetInnerHTML={{ __html: slide.previewText }} />
      </div>
      <div className={classes.actions}>
        <DragIcon ref={dragRef} />
        <IconButton size="small" color="primary" data-testid={`edit-slide-${slide.id}`} onClick={handleEditSlideClick}>
          <Edit />
        </IconButton>
        <SlidingConfirmation
          title="Delete slide (group)?"
          context="Are you sure you want to delete this slide (group) and all its contents?"
          disagreeAction="Cancel"
          agreeAction="Delete"
          isWarning
        >
          <IconButton size="small" color="secondary" data-testid={`delete-slide-${slide.id}`} onClick={handleDelete}>
            <Delete />
          </IconButton>
        </SlidingConfirmation>
      </div>
    </div>
  );
}

export default React.forwardRef<HTMLDivElement, SlideDisplayProps>((props, ref) => (
  <SlideDisplay {...props} dragRef={ref} />
));
