import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { oneOf, string } from 'prop-types';

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';

import Stage from './stage';
import PageWrapper from './page-wrapper';
import Toolbar from './toolbar';
import Loader from './loader';
import LinkAreaWrapper from './link-area-layer';
import AnswerSetWrapper from './answer-set-layer';
import { setCurrentPage, setScale } from '../../../../actions/codex-editor';
import { getActiveTab } from '../../../../selectors/codex-editor';
import ManualMarginsLayer from './manual-margins-layer';
import useManualMapping from './hooks/use-manual-mapping';
import Page from './page';

const useStyles = makeStyles(() => ({
  preview: {
    backgroundColor: '#D7D7D7',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
  },
  notFound: {
    marginTop: '16px',
  },
  pdf: {},
}));

const INITIAL_STATE = {
  book: undefined,
  answer: undefined,
  cover: undefined,
  backCover: undefined,
  manual: undefined,
  showManualLayer: false,
  showAnswerLayer: false,
  showBookLayer: true,
  zoomedToFit: false,
  loading: false,
};

const EDITOR_ACTIONS = {
  SHOW_ANSWER_LAYER: 'SHOW_ANSWER_LAYER',
  SHOW_MANUAL_LAYER: 'SHOW_MANUAL_LAYER',
  SHOW_BOOK_LAYER: 'SHOW_BOOK_LAYER',
  LAYER_FETCHED: 'LAYER_FETCHED',
  ZOOM_TO_FIT: 'ZOOM_TO_FIT',
  LOADING_STATE_CHANGED: 'LOADING_STATE_CHANGED',
};

function reducer(state, action) {
  switch (action && action.type) {
    case EDITOR_ACTIONS.SHOW_ANSWER_LAYER: {
      return {
        ...state,
        showAnswerLayer: action.payload,
      };
    }
    case EDITOR_ACTIONS.SHOW_MANUAL_LAYER: {
      return {
        ...state,
        showManualLayer: action.payload,
      };
    }
    case EDITOR_ACTIONS.SHOW_BOOK_LAYER: {
      return {
        ...state,
        showBookLayer: action.payload,
      };
    }
    case EDITOR_ACTIONS.LAYER_FETCHED: {
      const { layer, info } = action.payload;

      return {
        ...state,
        [layer]: info,
      };
    }
    case EDITOR_ACTIONS.ZOOM_TO_FIT: {
      return {
        ...state,
        zoomedToFit: action.payload,
      };
    }
    case EDITOR_ACTIONS.LOADING_STATE_CHANGED: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
}
export default function Editor({ digibookId, manualType }) {
  const classes = useStyles();

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const {
    book,
    answer,
    cover,
    backCover,
    manual,
    showManualLayer,
    showAnswerLayer,
    zoomedToFit,
    loading,
    showBookLayer,
  } = state;

  const reduxDispatch = useDispatch();

  const {
    pagination: { currentPage, totalPages, viewMode },
    scale,
    initial: { manualMargins, totalManualPages },
    viewport,
  } = useSelector(s => s.codexEditor);

  const activeTab = useSelector(getActiveTab);

  const handleLoadingChanged = useCallback(newLoading => {
    dispatch({
      type: EDITOR_ACTIONS.LOADING_STATE_CHANGED,
      payload: newLoading,
    });
  }, []);

  const handleAnswerLayerToggled = useCallback(show => {
    dispatch({
      type: EDITOR_ACTIONS.SHOW_ANSWER_LAYER,
      payload: show,
    });
  }, []);

  const handleManualLayerToggled = useCallback(show => {
    dispatch({
      type: EDITOR_ACTIONS.SHOW_MANUAL_LAYER,
      payload: show,
    });
  }, []);

  const handleBookLayerToggled = useCallback(show => {
    dispatch({
      type: EDITOR_ACTIONS.SHOW_BOOK_LAYER,
      payload: show,
    });
  }, []);

  const handleLayerFetched = useCallback((layer, info) => {
    dispatch({
      type: EDITOR_ACTIONS.LAYER_FETCHED,
      payload: {
        layer,
        info,
      },
    });
  }, []);

  const handleZoomChange = useCallback(
    (newScale, zoomToFit) => {
      dispatch({
        type: EDITOR_ACTIONS.ZOOM_TO_FIT,
        payload: zoomToFit,
      });

      reduxDispatch(setScale(newScale));
    },
    [reduxDispatch],
  );

  const [currentManualPage, setCurrentManualPage] = useState(0);

  const handlePageChange = useCallback(
    pageNumber => {
      const onManualTab = activeTab === 4;

      const totalPagesOfCorrectLayer = onManualTab ? totalManualPages : totalPages;

      if (onManualTab) {
        setCurrentManualPage(pageNumber);
      } else {
        reduxDispatch(setCurrentPage(pageNumber, viewMode, totalPagesOfCorrectLayer));
      }
    },
    [reduxDispatch, viewMode, totalPages, totalManualPages, activeTab],
  );

  useEffect(() => {
    if (!manual) handleManualLayerToggled(false);
  }, [manual, handleManualLayerToggled]);

  useEffect(() => {
    if (!answer) handleAnswerLayerToggled(false);
  }, [answer, handleAnswerLayerToggled]);

  useEffect(() => {
    if (activeTab > 0) handleManualLayerToggled(false);
  }, [activeTab, handleManualLayerToggled]);

  const bookToManualMap = useManualMapping();

  const layers = useMemo(() => {
    const canvasses = [];

    if (book && !showManualLayer && activeTab !== 4) {
      canvasses.push(
        <PageWrapper
          key="book"
          id="book"
          mainPdf={book}
          coverPdf={cover}
          backCoverPdf={backCover}
          totalPages={totalPages}
          scale={scale}
          page={currentPage}
          viewMode={viewMode}
        />,
      );
    }

    if (answer && showAnswerLayer && !showManualLayer && activeTab !== 4) {
      canvasses.push(
        <PageWrapper
          key="answer"
          id="answer"
          mainPdf={answer}
          scale={scale}
          page={currentPage}
          viewMode={viewMode}
          totalPages={totalPages}
          transparent
        />,
      );
    }

    if (activeTab === 0 && manual && showManualLayer) {
      canvasses.push(
        <PageWrapper
          key="manual"
          id="manual"
          mainPdf={manual}
          coverPdf={cover}
          backCoverPdf={backCover}
          totalPages={totalManualPages}
          scale={scale}
          page={currentPage}
          viewMode={viewMode}
        />,
        <ManualMarginsLayer key="manualMargins" />,
      );
    }

    if (activeTab === 2) canvasses.push(<LinkAreaWrapper key="linkAreaWrapper" digibookId={digibookId} />);

    if (activeTab === 3) canvasses.push(<AnswerSetWrapper key="answerSetWrapper" digibookId={digibookId} />);

    if (activeTab === 4 && manual) {
      canvasses.push(
        <PageWrapper
          key="manual"
          id="manual"
          mainPdf={manual}
          coverPdf={cover}
          backCoverPdf={backCover}
          totalPages={totalManualPages}
          scale={scale}
          page={currentManualPage}
          viewMode={viewMode}
        />,
      );

      if (showBookLayer && currentManualPage > 0 && currentManualPage <= totalManualPages) {
        const leftPage = Object.keys(bookToManualMap).find(key => bookToManualMap[key] === currentManualPage);
        const rightPage = Object.keys(bookToManualMap).find(key => bookToManualMap[key] === currentManualPage + 1);

        const coordinatesOfBookOnManual = {
          top: `${manualMargins.top * viewport.height}px`,
          left: `${manualMargins.left * 2 * viewport.width}px`,
        };

        if (leftPage && rightPage && currentManualPage !== 1) {
          canvasses.push(
            <PageWrapper
              key="book-on-manual"
              id="book-on-manual"
              mainPdf={book}
              coverPdf={cover}
              backCoverPdf={backCover}
              totalPages={totalPages}
              scale={scale / manualMargins.width}
              page={Number(leftPage)}
              rightPage={Number(rightPage)}
              viewMode={viewMode}
              coordinates={coordinatesOfBookOnManual}
              manualPage
            />,
          );
        } else if (leftPage) {
          const style = { position: 'absolute', ...coordinatesOfBookOnManual };

          canvasses.push(
            <div key="left-page-on-manual" className={classes.pdf} style={style}>
              <Page
                id="left-page-on-manual"
                page={Number(leftPage)}
                scale={scale / manualMargins.width}
                pdf={book}
                setViewportOnRender={false}
              />
            </div>,
          );
        } else if (rightPage && currentManualPage > 1) {
          const style = {
            position: 'absolute',
            top: coordinatesOfBookOnManual.top,
            left: `${viewport.width}px`,
          };

          canvasses.push(
            <div key="right-page-on-manual" className={classes.pdf} style={style}>
              <Page
                id="right-page-on-manual"
                page={Number(rightPage)}
                scale={scale / manualMargins.width}
                pdf={book}
                setViewportOnRender={false}
              />
            </div>,
          );
        }
      }
    }

    return canvasses;
  }, [
    book,
    showManualLayer,
    manual,
    answer,
    showAnswerLayer,
    activeTab,
    digibookId,
    cover,
    backCover,
    totalPages,
    scale,
    currentPage,
    viewMode,
    showBookLayer,
    manualMargins,
    bookToManualMap,
    totalManualPages,
    classes.pdf,
    viewport,
    currentManualPage,
  ]);

  return (
    <div className={classes.preview}>
      {(book || manual) && !loading && (
        <>
          <Stage zoomToFit={zoomedToFit} onPan={() => handleZoomChange(scale, false)}>
            {layers}
          </Stage>
          <Toolbar
            currentPage={activeTab === 4 ? currentManualPage : currentPage}
            totalPages={activeTab === 4 ? totalManualPages : totalPages}
            scale={scale}
            viewMode={viewMode}
            setPage={handlePageChange}
            setScale={scaleToSet => handleZoomChange(scaleToSet, false)}
            zoomToFit={() => handleZoomChange(1, true)}
            toggleAnswer={handleAnswerLayerToggled}
            toggleManual={handleManualLayerToggled}
            answerToggled={showAnswerLayer}
            manualToggled={showManualLayer}
            answerDisabled={!answer || showManualLayer}
            manualDisabled={!manual || manualType === 'pop-up-manual'}
            toggleBook={handleBookLayerToggled}
            bookToggled={showBookLayer}
          />
        </>
      )}

      {!book && loading && (
        <Typography className={classes.notFound} variant="h5">
          Loading book...
        </Typography>
      )}

      {!loading && !book && (
        <Typography className={classes.notFound} variant="h5">
          No book found...
        </Typography>
      )}

      <Loader onLayerFetched={handleLayerFetched} loading={loading} setLoading={handleLoadingChanged} />
    </div>
  );
}

Editor.propTypes = {
  digibookId: string.isRequired,
  manualType: oneOf(['manual-layer', 'pop-up-manual']),
};

Editor.defaultProps = {
  manualType: 'manual-layer',
};
