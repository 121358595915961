import React from 'react';
import { useDrop } from 'react-dnd';
import { useParams } from 'react-router';
import { tss } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

import { SlideGroup, deleteSlideGroup } from '../../../api/slideSet';
import SingleSlide from './single-slide';
import GroupSlide from './group-slide';
import { DND_TYPES, Item } from '../drag-and-drop-utils';
import DropZone from './drop-zone';
import useGroupDropZone from '../hooks/use-group-drop-zone';
import DragIcon from './drag-icon';
import SlidingConfirmation from '../../../components/sliding-confirmation';

type GroupProps = {
  group: SlideGroup;
  groupIndex: number;
  addSlideToGroup: (originalGroupId: number, targetGroupId: number, slide: SlideGroup['slides'][number]) => void;
  reorderGroupSlides: (groupId: number, slides: SlideGroup['slides'][number][]) => void;
  reorderGroups: (originalGroup: SlideGroup, targetGroup: SlideGroup, direction: 'up' | 'down') => void;
  moveGroupSlideToSingleGroup: (
    movedGroup: SlideGroup,
    movedSlide: SlideGroup['slides'][number],
    targetGroup: SlideGroup,
    direction: 'up' | 'down',
  ) => void;
  refetchData: () => void;
};

const useStyles = tss.create(({ theme }) => ({
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
    width: '7rem',
    minWidth: '7rem',
  },
  groupDropzone: {
    height: '6rem',
    flexGrow: 1,
    border: '1px dashed black',
  },
  slide: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(1),
    '& img': {
      width: '12rem',
    },
  },
  hide: {
    display: 'none',
  },
  hovered: {
    backgroundColor: theme.palette.primary[100],
  },
}));

export default function Group({
  group,
  groupIndex,
  addSlideToGroup,
  reorderGroupSlides,
  reorderGroups,
  moveGroupSlideToSingleGroup,
  refetchData,
}: GroupProps) {
  const { setId, nodeId, moduleId, versionId } = useParams();
  const { classes, cx } = useStyles();

  const needsTopDropzone = groupIndex === 0;

  const accept = [DND_TYPES.GROUP_SLIDE, DND_TYPES.SINGLE_GROUP, DND_TYPES.NON_SINGLE_GROUP];
  const [{ isOverTop }, dropTop] = useDrop<Item, unknown, { isOverTop: boolean }>(
    () => ({
      accept,
      collect: monitor => ({
        isOverTop: monitor.isOver(),
      }),
      drop: item => {
        if (item.type === DND_TYPES.GROUP_SLIDE) {
          // remove from original group, add new group with id null
          moveGroupSlideToSingleGroup(item.group, item.slide, group, 'up');
        } else {
          // reorder groups
          if (item.group.id === group.id) return;

          reorderGroups(item.group, group, 'up');
        }
      },
    }),
    [group, reorderGroups],
  );

  const [{ isOverBottom }, dropBottom] = useDrop<Item, unknown, { isOverBottom: boolean }>(
    () => ({
      accept,
      collect: monitor => ({
        isOverBottom: monitor.isOver(),
      }),
      drop: item => {
        if (item.type === DND_TYPES.GROUP_SLIDE) {
          // remove from original group, add new group with id null
          moveGroupSlideToSingleGroup(item.group, item.slide, group, 'down');
        } else {
          // reorder groups
          if (item.group.id === group.id) return;

          reorderGroups(item.group, group, 'down');
        }
      },
    }),
    [group, reorderGroups],
  );

  const handleDelete = async () => {
    if (!moduleId || !nodeId || !setId || !versionId) return;
    await deleteSlideGroup(moduleId, nodeId, setId, versionId, group.id);
    refetchData();
  };

  const {
    isDragging: isDraggingGroup,
    dragRef,
    previewRef,
    isOver: isOverGroup,
    dropRef,
  } = useGroupDropZone({ addSlideToGroup, group });

  return (
    <div
      role="listitem"
      key={`group-${group.id}`}
      data-testid={`group-${group.id}`}
      className={cx({ [classes.group]: true, [classes.hide]: isDraggingGroup })}
      ref={previewRef}
    >
      {needsTopDropzone && <DropZone isOver={isOverTop} ref={dropTop} data-testid={`drop-zone-${group.id}-top`} />}
      {group.type !== 'SINGLE' && (
        <div
          className={cx({ [classes.slide]: true, [classes.hide]: isDraggingGroup })}
          data-testid={`group-${group.id}-dropzone`}
          ref={dropRef}
        >
          <Typography>{groupIndex + 1}</Typography>
          <div
            data-testid={`drop-group-${group.id}`}
            className={cx({ [classes.groupDropzone]: true, [classes.hovered]: isOverGroup })}
          />
          <div className={classes.actions}>
            <Typography variant="caption">{group.type}</Typography>
            <DragIcon ref={dragRef} />

            <SlidingConfirmation
              title="Delete slide (group)?"
              context="Are you sure you want to delete this slide (group) and all its contents?"
              disagreeAction="Cancel"
              agreeAction="Delete"
              isWarning
            >
              <IconButton data-testid={`delete-group-${group.id}`} color="secondary" onClick={handleDelete}>
                <Delete />
              </IconButton>
            </SlidingConfirmation>
          </div>
        </div>
      )}
      {group.slides.map((slide, slideIndex) =>
        group.type === 'SINGLE' ? (
          <SingleSlide
            key={`slide-${slide.id}`}
            group={group}
            slide={slide}
            indexString={`${groupIndex + 1}`}
            refetchData={refetchData}
          />
        ) : (
          <GroupSlide
            key={`slide-${slide.id}`}
            group={group}
            slide={slide}
            indexString={`${groupIndex + 1}.${slideIndex + 1}`}
            onMove={reorderGroupSlides}
            refetchData={refetchData}
          />
        ),
      )}
      <DropZone isOver={isOverBottom} ref={dropBottom} data-testid={`drop-zone-${group.id}-bottom`} />
    </div>
  );
}
