import React from 'react';
import { shape, func, bool, string, arrayOf } from 'prop-types';

import { TableRow, TableCell, Checkbox } from '@mui/material';

import MediaLinkContentType from './media-link-content-type';
import MediaLinkHierarchy from './media-link-hierarchy';

import { getPublishingStateForMediaLink } from '../../../../../utils/medialinks';

function MediaLinkRow({ medialink, medialinkSelected, isSelected, showHierarchy }) {
  return (
    <TableRow hover role="checkbox" onClick={e => medialinkSelected(e, medialink.id)}>
      <TableCell padding="dense">
        <Checkbox checked={isSelected} />
      </TableCell>
      <TableCell component="th" scope="row" padding="dense">
        {medialink.name}
      </TableCell>
      <TableCell>
        <MediaLinkContentType contentTypeId={medialink.contentType} />
      </TableCell>
      {showHierarchy && (
        <TableCell>
          <MediaLinkHierarchy hierarchy={medialink.hierarchy} />
        </TableCell>
      )}
      <TableCell>{getPublishingStateForMediaLink(medialink.reviewState, medialink.published)}</TableCell>
    </TableRow>
  );
}

MediaLinkRow.propTypes = {
  medialink: shape({
    hierarchy: arrayOf(string),
  }).isRequired,
  medialinkSelected: func.isRequired,
  isSelected: bool.isRequired,
  showHierarchy: bool.isRequired,
};

export default MediaLinkRow;
