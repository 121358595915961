import React from 'react';
import { useParams } from 'react-router';
import { OndemandVideoOutlined } from '@mui/icons-material';
import LinkIconButton from '../../../components/buttons/link-icon-button';
import { SlideSetVersion } from '../../../api/slideSetVersion';

type EditProps = {
  version: SlideSetVersion;
};

const PreviewAction = ({ version }: EditProps) => {
  const { setId } = useParams();
  return (
    <LinkIconButton
      buttonProps={{
        id: 'edit',
        size: 'large',
        color: 'primary',
        'data-testid': 'preview-button',
      }}
      target="_blank"
      to={`${DOCEO_TEMPLATE_URL}/${setId}?versionId=${version.id}`}
    >
      <span title="Preview">
        <OndemandVideoOutlined />
      </span>
    </LinkIconButton>
  );
};

export default PreviewAction;
