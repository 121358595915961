import PropTypes from 'prop-types';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel as CancelIcon, CloudDownload as DownloadIcon } from '@mui/icons-material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
  icon: {
    padding: useTheme().spacing(0.5),
  },
}));

function UploadAdornment({ file, loading, unlinkFile, cancelUpload, onDownload }) {
  const classes = useStyles();

  if (loading) {
    return (
      <InputAdornment position="end">
        <CircularProgress className={classes.icon} size={20} />
        <IconButton className={classes.icon} onClick={cancelUpload} size="large">
          <CancelIcon color="secondary" />
        </IconButton>
      </InputAdornment>
    );
  }

  if (file) {
    return (
      <InputAdornment position="end">
        <IconButton className={classes.icon} onClick={onDownload} size="large">
          <DownloadIcon color="primary" />
        </IconButton>
        <IconButton className={classes.icon} onClick={unlinkFile} size="large">
          <CancelIcon color="secondary" />
        </IconButton>
      </InputAdornment>
    );
  }

  return null;
}

UploadAdornment.propTypes = {
  file: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  unlinkFile: PropTypes.func.isRequired,
  cancelUpload: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default UploadAdornment;
