import api from '../../services/api';

type BaseParams = {
  moduleId: string;
  nodeId: string;
  setId: string;
  versionId: string;
  groupId: string;
  slideId: string;
};

export function updatePreviewText({
  moduleId,
  nodeId,
  setId,
  versionId,
  groupId,
  slideId,
  previewText,
}: BaseParams & { previewText: string }) {
  return api.put(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${groupId}/slides/${slideId}/preview-text`,
    { previewText },
  );
}

export type SlideInfo = {
  previewText: string;
  slideSourceId?: string;
  revealSourceId?: string;
  slideSourceUrl?: string;
  revealSourceUrl?: string;
};

export async function getSlideInfo({ moduleId, nodeId, setId, versionId, groupId, slideId }: BaseParams) {
  const { data } = await api.get<SlideInfo>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${groupId}/slides/${slideId}`,
  );

  return data;
}

export const ACTIVE_AREA_TYPE = {
  REVEAL: 'REVEAL',
  NAVIGATION: 'NAVIGATION',
  WEBLINK: 'WEBLINK',
  EMBED: 'EMBED',
} as const;

export type ActiveAreaType = (typeof ACTIVE_AREA_TYPE)[keyof typeof ACTIVE_AREA_TYPE];

export type Shape = {
  top: number;
  left: number;
  width: number;
  height: number;
  angle: number;
};

export type ActiveArea = Shape & {
  id: number;
  name: string;
  createdAt: string;
  type: ActiveAreaType;
};

export async function getActiveAreas({ moduleId, nodeId, setId, versionId, groupId, slideId }: BaseParams) {
  const { data } = await api.get<{ data: ActiveArea[] }>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${groupId}/slides/${slideId}/active-areas`,
  );

  return data.data;
}

export async function deleteActiveArea({
  moduleId,
  nodeId,
  setId,
  versionId,
  groupId,
  slideId,
  areaId,
}: BaseParams & { areaId: number }) {
  await api.delete(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${groupId}/slides/${slideId}/active-areas/${areaId}`,
  );
}

export async function getSecuredFile(securedFileId: string) {
  const { data } = await api.get(`/shell/secured-files/${securedFileId}`);
  return data;
}

export async function deleteSourceFileOfSlide({
  moduleId,
  nodeId,
  setId,
  versionId,
  groupId,
  slideId,
  securedFileId,
}: BaseParams & { securedFileId: string }) {
  await api.delete(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${groupId}/slides/${slideId}/source/${securedFileId}`,
  );
}

export async function putActiveArea({
  moduleId,
  nodeId,
  setId,
  versionId,
  groupId,
  slideId,
  activeAreaId,
  name,
  type,
}: BaseParams & { activeAreaId: string; name: string; type: string }) {
  await api.put(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${groupId}/slides/${slideId}/active-areas/${activeAreaId}`,
    { name, type },
  );
}

export async function addActiveArea({ moduleId, nodeId, setId, versionId, groupId, slideId }: BaseParams, area: Shape) {
  await api.post(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${groupId}/slides/${slideId}/active-areas`,
    area,
  );
}
