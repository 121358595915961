import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import JoditEditor, { IJoditEditorProps } from 'jodit-react';
import debounce from 'lodash.debounce';
import { updatePreviewText } from '../api';

import '../../../../scss/text-editor.scss';
import { SlideEditorParams } from '../utils';

// all default buttons can be found on https://xdsoft.net/jodit/play.html?currentTab=Buttons
const joditOptions = ['bold', 'strikethrough', 'underline', 'italic', 'eraser', '|', 'ul', 'ol', '|', 'undo', 'redo'];

export default function TextEditor({ previewText }: { previewText: string }) {
  const editor = useRef(null);
  const [content, setContent] = useState(previewText);
  const params = useParams() as Required<SlideEditorParams>;

  const config = useMemo<IJoditEditorProps['config']>(
    () => ({
      readonly: false, // https://xdsoft.net/jodit/docs/,
      placeholder: 'Start typing',
      buttons: [...joditOptions],
      height: '100%',
      width: '100%',
      statusbar: false,
      toolbarAdaptive: false,
      allowResizeX: false,
      allowResizeY: false,
      askBeforePasteHTML: false,
    }),
    [],
  );

  const save = useMemo(
    () =>
      debounce((newContent: string) => {
        return updatePreviewText({ ...params, previewText: newContent });
      }, 1000),
    [params],
  );

  const update = newContent => {
    setContent(newContent);
    save(newContent);
  };

  useEffect(() => {
    return () => {
      save.flush();
    };
  }, [params, save]);

  return <JoditEditor ref={editor} value={content} config={config} onChange={update} />;
}
