import React from 'react';
import { string, shape, arrayOf, func, object } from 'prop-types';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import { Paper, Button, FormHelperText } from '@mui/material';

import { fetchMediaLinksForLinkArea } from '../../../../../actions/medialinks';

import { getMediaLinksForIdsOrderedByToc } from '../../../../../selectors/medialinks';

import EnhancedTableToolbar from './enhanced-table-toolbar';
import AddMedialinkModal from './add-media-link-modal';
import MediaLinkTable from './media-link-table';

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
});

export class LinkAreaMediaLinkLinker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalOpened: false,
      selectedMedialinks: [],
    };
  }

  componentDidMount() {
    const { fetchMediaLinkDetails, linkAreaId } = this.props;
    if (linkAreaId) {
      fetchMediaLinkDetails(linkAreaId);
    }
  }

  isSelected = id => {
    const { selectedMedialinks } = this.state;
    return selectedMedialinks.indexOf(id) !== -1;
  };

  handleDelete = () => {
    const { selectedMedialinks } = this.state;
    const { setFieldValue, mediaLinks } = this.props;

    setFieldValue(
      'mediaLinks',
      mediaLinks.filter(ml => !selectedMedialinks.includes(ml)),
    );

    this.setState({
      selectedMedialinks: [],
    });
  };

  handleAdd = addedMediaLinks => {
    const { setFieldValue, mediaLinks } = this.props;

    setFieldValue('mediaLinks', mediaLinks.concat(addedMediaLinks));
    this.handleCloseModal();
  };

  handleMedialinkSelect = (event, id) => {
    const { selectedMedialinks } = this.state;
    const selectedIndex = selectedMedialinks.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMedialinks, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMedialinks.slice(1));
    } else if (selectedIndex === selectedMedialinks.length - 1) {
      newSelected = newSelected.concat(selectedMedialinks.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMedialinks.slice(0, selectedIndex),
        selectedMedialinks.slice(selectedIndex + 1),
      );
    }

    this.setState({ selectedMedialinks: newSelected });
  };

  handleSelectAllClick = () => {
    const { mediaLinks } = this.props;
    const { selectedMedialinks } = this.state;

    if (selectedMedialinks.length === mediaLinks.length) {
      this.setState({
        selectedMedialinks: [],
      });
    } else {
      this.setState({
        selectedMedialinks: [...mediaLinks],
      });
    }
  };

  handleOpenModal = () => {
    this.setState({
      addModalOpened: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      addModalOpened: false,
    });
  };

  render() {
    const { classes, mediaLinksForLinkArea, mediaLinks, error } = this.props;

    const { addModalOpened, selectedMedialinks } = this.state;

    return (
      <Paper>
        <EnhancedTableToolbar numSelected={selectedMedialinks.length} handleDelete={this.handleDelete} />
        <MediaLinkTable
          handleSelection={this.handleMedialinkSelect}
          handleSelectAll={this.handleSelectAllClick}
          mediaLinks={mediaLinksForLinkArea}
          allSelected={selectedMedialinks.length > 0 && selectedMedialinks.length === mediaLinksForLinkArea.length}
          isSelected={this.isSelected}
          showHierarchy
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
        <Button variant="contained" color="primary" onClick={this.handleOpenModal} className={classes.button}>
          Add media link
        </Button>
        {addModalOpened && (
          <AddMedialinkModal
            linkAreaMediaLinks={mediaLinks}
            handleClose={this.handleCloseModal}
            handleSave={this.handleAdd}
          />
        )}
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  mediaLinksForLinkArea: getMediaLinksForIdsOrderedByToc(state, ownProps.mediaLinks),
});

const mapDispatchToProps = {
  fetchMediaLinkDetails: fetchMediaLinksForLinkArea,
};

LinkAreaMediaLinkLinker.propTypes = {
  classes: object.isRequired,
  mediaLinks: arrayOf(string),
  setFieldValue: func.isRequired,
  linkAreaId: string,
  mediaLinksForLinkArea: arrayOf(
    shape({
      id: string,
      name: string,
      publishingState: string,
      contentType: string,
      hierarchy: arrayOf(string),
    }),
  ),
  fetchMediaLinkDetails: func.isRequired,
  error: string,
};

LinkAreaMediaLinkLinker.defaultProps = {
  mediaLinks: [],
  mediaLinksForLinkArea: [],
  linkAreaId: undefined,
  error: undefined,
};

export const ConnectedLinkAreaMediaLinkLinker = connect(mapStateToProps, mapDispatchToProps)(LinkAreaMediaLinkLinker);

export default withStyles(styles)(ConnectedLinkAreaMediaLinkLinker);
