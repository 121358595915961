import { createTheme } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';

export default createTheme({
  typography: {
    useNextVariants: true,
  },
  drawerWidth: 240,
  palette: {
    primary: blue,
    secondary: red,
  },
});
