import * as Yup from 'yup';

const ignoreEmptyStringNumber = Yup.number().transform(value => (Number.isNaN(value) ? undefined : value));

export default Yup.object().shape({
  name: Yup.string().required('Required'),
  productionalState: Yup.number().required('Required'),
  bookLayer: Yup.string().nullable(true),
  answerLayer: Yup.string().nullable(true),
  disableAnswerLayerBlend: Yup.boolean(),
  solutionsIncluded: Yup.boolean(),
  pageRanges: Yup.array().of(
    Yup.object().shape({
      from: ignoreEmptyStringNumber.min(0),
      to: ignoreEmptyStringNumber
        .min(0)
        .when('from', (other, schema) => (other ? schema.min(other).required() : schema)),
      keepMediaVisibleTo: ignoreEmptyStringNumber
        .min(0)
        .when('from', (other, schema) => (other ? schema.min(other).required() : schema)),
      manualFrom: ignoreEmptyStringNumber.min(0),
    }),
  ),
  enableManual: Yup.boolean(),
});
