import qs from 'query-string';

import Storage from './storage';
import StorageKeys from './storage-keys';

export const logOn = returnUrl => {
  const { location } = window;

  const query = qs.stringify({
    provider: 'pelckmans',
    redirect_uri: `${location.protocol}//${location.host}/oauth2-redirect`,
    returnUrl,
  });

  window.location.assign(`${PORTAAL_API_URL}/api/oauth/authorize?${query}`);
};

export const logOff = () => {
  Storage.remove(StorageKeys.ACCESS_TOKEN);
  Storage.remove(StorageKeys.REFRESH_TOKEN);

  const { location } = window;

  const query = qs.stringify({
    provider: 'pelckmans',
    return_url: `${location.protocol}//${location.host}`,
  });

  window.location.assign(`${PORTAAL_API_URL}/api/oauth/logout?${query}`);
};
