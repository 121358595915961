import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { tss } from 'tss-react/mui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Button } from '@mui/material';

import { SlideGroup, SlideSetVersionType, getSlideGroups, postSlideGroup } from '../../api/slideSet';

import Group from './components/group';
import useGroupActions from './hooks/use-group-actions';
import { getSlideSetVersion } from '../../api/slideSetVersion';

const useStyles = tss.create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },
    padding: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    padding: '1rem 0',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  groupDropzone: {
    height: '8rem',
    flexGrow: 1,
    border: '1px dashed black',
  },
  groupSlides: {
    paddingLeft: '2rem',
  },
  slide: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 8,
    '& img': {
      width: '12rem',
    },
  },
  textArea: {
    height: '100%',
    width: '100%',
    minWidth: '20rem',
    margin: 0,
    border: '1px solid #ABABAB',
    backgroundColor: '#F0F0F0',
  },
  textContainer: {
    flexGrow: 1,
  },
}));

export default function SlidesetVersionDetail() {
  const { setId, versionId, nodeId, moduleId } = useParams();
  const [groups, setGroups] = React.useState<SlideGroup[]>([]);
  const { classes } = useStyles();
  const navigate = useNavigate();

  const fetchVersion = useCallback(async () => {
    if (!moduleId || !nodeId || !setId || !versionId) return;
    try {
      const { state } = await getSlideSetVersion(moduleId, nodeId, setId, versionId);
      if (state === 'ARCHIVED' || state === 'PUBLISHED') navigate('/digislides');
    } catch (e) {
      navigate('/digislides');
    }
  }, [moduleId, nodeId, setId, versionId, navigate]);

  const fetchData = useCallback(async () => {
    if (!moduleId || !nodeId || !setId || !versionId) {
      throw new Error('Can not use SlidesetVersionDetail outside of module');
    }
    try {
      const slideGroups = await getSlideGroups(moduleId, nodeId, setId, versionId);
      return slideGroups;
    } catch (e) {
      navigate('/digislides');
      return [];
    }
  }, [moduleId, nodeId, setId, versionId, navigate]);

  const { addSlideToGroup, moveGroupSlideToSingleGroup, reorderGroupSlides, reorderGroups } = useGroupActions({
    fetchData,
    groups,
    setGroups,
  });

  useEffect(() => {
    let cancelled = false;

    fetchVersion();

    fetchData().then(data => {
      if (!cancelled) setGroups(data);
    });

    return () => {
      cancelled = true;
    };
  }, [fetchData, fetchVersion]);

  const createGroup = async (type: SlideSetVersionType) => {
    if (!moduleId || !nodeId || !setId || !versionId) return null;

    const { groupId, slideId } = await postSlideGroup(moduleId, nodeId, setId, versionId, type);

    if (slideId) return navigate(`groups/${groupId}/slides/${slideId}`);

    return fetchData().then(setGroups);
  };

  const refetchData = () => {
    fetchData().then(setGroups);
  };

  return (
    <div data-testid="slide-version" className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={() => createGroup('SINGLE')}>
          Add slide
        </Button>
        <Button variant="contained" color="primary" onClick={() => navigate('import')}>
          Import slides from pdf
        </Button>
        <Button variant="contained" color="primary" onClick={() => createGroup('SEQUENTIAL')}>
          Add sequential slide group
        </Button>
        <Button variant="contained" color="primary" onClick={() => createGroup('NONSEQUENTIAL')}>
          Add non-sequential slide group
        </Button>
      </div>
      <div role="list">
        <DndProvider backend={HTML5Backend}>
          {groups.map((group, groupIndex) => (
            <Group
              key={`group-${group.id}`}
              group={group}
              groupIndex={groupIndex}
              addSlideToGroup={addSlideToGroup}
              reorderGroupSlides={reorderGroupSlides}
              reorderGroups={reorderGroups}
              moveGroupSlideToSingleGroup={moveGroupSlideToSingleGroup}
              refetchData={refetchData}
            />
          ))}
        </DndProvider>
      </div>
    </div>
  );
}
