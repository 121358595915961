import FabricService from '../../codex-detail/components/editor/services/fabric-service';
import { ActiveArea, Shape } from '../api';

export default class ActiveAreaService extends FabricService {
  constructor(id: string) {
    super(id);
    this.RECTANGLE_FILL = 'rgba(240,20,20,0.15)';
    this.MARKER_FILL = 'rgb(240,20,20)';
  }

  setMoving = () => {};

  onAreaMoved = () => {};

  drawActiveAreas(activeAreas: ActiveArea[]) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects());

    const scaleX = this.fabricCanvas.width;

    const scaleY = this.fabricCanvas.height;

    const denormalizedActiveAreas = activeAreas.map(area =>
      FabricService.getAbsoluteCoordinatesForRectangle(area, scaleX, scaleY),
    );

    const objects: fabric.Object[] = this.createEventedRectanglesWithMarkers(
      denormalizedActiveAreas,
      this.setMoving,
      this.onAreaMoved,
      scaleX,
      scaleY,
    );

    objects.forEach(obj => {
      if (obj.type === 'rect') {
        this.fabricCanvas.add(obj);
      } else {
        this.fabricCanvas.bringToFront(obj);
      }
    });
  }

  startDrawingMode(onAreaDrawn: (shape: Shape) => Promise<void>) {
    this.setIsDrawingArea(true);
    this.freezeObjects();
    this.addSelectionListeners(onAreaDrawn);
  }

  stopDrawingMode() {
    this.setIsDrawingArea(false);
    this.fabricCanvas.off('mouse:down');
    this.fabricCanvas.off('mouse:up');
  }

  renderAll = () => {
    this.fabricCanvas.renderAll();
  };
}
