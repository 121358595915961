import { fabric } from 'fabric';

const CANVAS_BACKGROUND_COLOR = '#F0F0F0';

type Dimensions = { width: number; height: number };
export default class ImageCanvas {
  canvas: fabric.Canvas;

  constructor(id: string, dimensions: Dimensions) {
    this.canvas = new fabric.Canvas(id, {
      renderOnAddRemove: false,
      ...dimensions,
      backgroundColor: '#F0F0F0',
    });
  }

  showSlide(slide: HTMLImageElement | null) {
    this.canvas.remove(...this.canvas.getObjects());
    this.canvas.backgroundColor = CANVAS_BACKGROUND_COLOR;

    let slideToAdd: fabric.Image | fabric.Rect | undefined;

    if (!slide) slideToAdd = this.createEmptySlide();
    else {
      slideToAdd = new fabric.Image(slide, {
        left: 0,
        top: 0,
        // @ts-expect-error meta is not defined in fabric.
        meta: 'slide',
        selectable: false,
        hasControls: false,
        evented: false,
      });
    }

    this.canvas.add(slideToAdd);
    this.adjustCanvasToSlide();
    this.render();
  }

  destroySlide() {
    this.canvas.remove(...this.canvas.getObjects());
    this.render();
  }

  adjustCanvasToSlide() {
    const PADDING = 0;
    // @ts-expect-error meta is not defined in fabric.
    const slide = this.canvas.getObjects().find(x => x.meta === 'slide') as fabric.Image;
    if (!slide) return;
    this.canvas.setZoom(1);

    const scaleHeightToFit = (this.canvas.height! - PADDING * 2) / slide.getScaledHeight();
    const scaleWidthToFit = (this.canvas.width! - PADDING * 2) / slide.getScaledWidth();

    const scale = Math.min(scaleHeightToFit, scaleWidthToFit);

    const fitToWidth = scaleWidthToFit < scaleHeightToFit;

    this.canvas.setZoom(scale);

    const neededHeight = slide.height! * scale;
    const neededWidth = slide.width! * scale;
    const targetY = fitToWidth ? Math.round((this.canvas.height! - neededHeight) / 2) : PADDING;

    const targetX = fitToWidth ? PADDING : Math.round((this.canvas.width! - neededWidth) / 2);

    this.setViewportLeftOffset(targetX);
    this.setViewportTopOffset(targetY);
  }

  resizeCanvas(dimensions: Dimensions) {
    this.canvas.setDimensions(dimensions);
    this.adjustCanvasToSlide();
  }

  setViewportLeftOffset(offset: number) {
    this.canvas.viewportTransform![4] = Math.round(offset);
    this.canvas.setViewportTransform(this.canvas.viewportTransform!);
  }

  setViewportTopOffset(offset: number) {
    this.canvas.viewportTransform![5] = Math.round(offset);
    this.canvas.setViewportTransform(this.canvas.viewportTransform!);
  }

  private createEmptySlide() {
    // aspect ratio is 16/9
    return new fabric.Rect({
      // @ts-expect-error meta is not defined in fabric.
      meta: 'slide',
      left: 0,
      top: 0,
      width: 2560, // default width of converted slides
      height: 1440, // default height of converted slides
      fill: '#000000',
      selectable: false,
      evented: false,
    });
  }

  destroy() {
    this.canvas.dispose();
  }

  render() {
    this.canvas.requestRenderAll();
  }
}
