import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import DetailView from '../../components/layouts/detailview';
import { postSlideSetVersion } from '../../api/slideSetVersion';
import VersionList from './components/VersionList';
import SlideSetVersionContext from '../../contexts/slideset-version-context';
import { getSlideSet, putSlideSetName } from '../../api/slideSet';
import { useAppSelector } from '../../hooks/store';
import { getCurrentPublishingHouse } from '../../selectors/publishing-house';
import { getTranslatedGeneral } from '../../utils/slideset-helper';

const hasErrors = (value: string) => {
  if (value.length === 0) return 'Name is required';
  if (value.length > 100) return 'Name is too long';
  return false;
};

const SlideSetDetail = () => {
  const { setId, moduleId, nodeId } = useParams();
  const { versions } = useContext(SlideSetVersionContext);
  const navigate = useNavigate();
  const publishingHouse = useAppSelector(getCurrentPublishingHouse);

  const [showArchived, setShowArchived] = React.useState(false);
  const [name, setName] = React.useState<string>('');
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const handlePost = async () => {
    if (!moduleId || !nodeId || !setId) return;
    const slideSetVersion = await postSlideSetVersion(moduleId, nodeId, setId);
    navigate(`versions/${slideSetVersion.id}`);
  };

  const handleUpdateName = React.useMemo(
    () =>
      debounce((value: string) => {
        if (hasErrors(value)) return;
        if (!moduleId || !nodeId || !setId) return;
        putSlideSetName(moduleId, nodeId, setId, value);
      }, 300),
    [moduleId, nodeId, setId],
  );

  useEffect(() => {
    return () => {
      handleUpdateName.flush();
    };
  }, [handleUpdateName]);

  const fetchSlideSet = useCallback(async () => {
    if (!moduleId || !nodeId || !setId) return;
    const slideSet = await getSlideSet(moduleId, nodeId, setId);

    if (slideSet) {
      const slideSetName =
        slideSet.name === 'general' ? getTranslatedGeneral(publishingHouse.studioGroup) : slideSet.name;
      setName(slideSetName);
      setLoaded(true);
    }
  }, [moduleId, nodeId, setId, publishingHouse]);

  useEffect(() => {
    fetchSlideSet();
  }, [fetchSlideSet, setId]);

  const filteredVersions = versions.filter(version => showArchived || version.state !== 'ARCHIVED');

  return (
    <DetailView title="Slide Set Version Manager" previousPage="/digislides">
      <Grid container marginBottom="32px">
        <TextField
          required
          label="Name"
          variant="outlined"
          value={name}
          error={Boolean(loaded && hasErrors(name))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
            handleUpdateName(event.target.value);
          }}
          helperText={hasErrors(name)}
        />
      </Grid>
      <Grid container>
        <Grid item marginRight="16px">
          <Button variant="contained" onClick={handlePost}>
            ADD DRAFT SLIDES
          </Button>
        </Grid>
        <Grid>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value={showArchived} onChange={() => setShowArchived(!showArchived)} />}
              label="Show archived"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <VersionList versions={filteredVersions} />
    </DetailView>
  );
};

export default SlideSetDetail;
