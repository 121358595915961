import React, { useCallback, useEffect, useRef, useState } from 'react';
import { tss } from 'tss-react/mui';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import SlideCanvas from '../services/slide-canvas';
import { ActiveAreaLayer } from './ActiveAreaLayer';

const useStyles = tss.create(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  canvasContainer: {
    flexGrow: 1,
    position: 'relative',
  },
}));

const VIEW_MODES = {
  SLIDE: 'SLIDE',
  REVEAL: 'REVEAL',
} as const;

type ViewMode = (typeof VIEW_MODES)[keyof typeof VIEW_MODES];
function isViewMode(value: string): asserts value is ViewMode {
  if (!(value === VIEW_MODES.SLIDE || value === VIEW_MODES.REVEAL)) {
    throw new Error('Invalid view mode value');
  }
}

type PreviewProps = {
  sourceUrl?: string;
  revealUrl?: string;
};

export default function Preview({ sourceUrl, revealUrl }: PreviewProps) {
  const fabricRef = React.useRef<SlideCanvas>();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [sourceImage, setSourceImage] = React.useState<HTMLImageElement | null>(null);
  const [revealImage, setRevealImage] = React.useState<HTMLImageElement | null>(null);

  const [viewMode, setViewMode] = React.useState<ViewMode>(
    revealUrl && !sourceUrl ? VIEW_MODES.REVEAL : VIEW_MODES.SLIDE,
  );

  const changeViewMode = useCallback(
    (vm: string) => {
      if (!fabricRef.current) return;

      isViewMode(vm);
      setViewMode(vm);

      if (vm === VIEW_MODES.SLIDE) fabricRef.current.showSlide(sourceImage);
      if (vm === VIEW_MODES.REVEAL) fabricRef.current.showSlide(revealImage);
    },
    [revealImage, sourceImage],
  );

  const { classes } = useStyles();

  useEffect(() => {
    fabricRef.current = new SlideCanvas('image-canvas', {
      width: containerRef.current!.clientWidth,
      height: containerRef.current!.clientHeight,
    });
  }, []);

  const initialRender = useRef(true);
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    let persist = true;

    async function loadLayers() {
      async function loadImage(url?: string): Promise<HTMLImageElement | undefined> {
        if (!url) return Promise.resolve(undefined);

        return new Promise((resolve, reject) => {
          const img = new Image();

          img.onload = () => resolve(img);
          img.onerror = () => {
            // Image might still be processing, trying again in 2 seconds if no new url has been provided meanwhile.
            if (persist) setTimeout(() => loadLayers(), 2000);
            reject();
          };
          img.oncancel = () => resolve(undefined);

          img.src = url;
        });
      }

      const promises = [sourceUrl, revealUrl].map(loadImage);
      const [source, reveal] = await Promise.all(promises);

      if (!persist) return;

      if (source) setSourceImage(source);
      else setSourceImage(null);

      if (reveal) setRevealImage(reveal);
      else setRevealImage(null);

      if (initialRender.current) {
        setInitialLoad(true);
        initialRender.current = false;
      }
    }

    loadLayers();

    return () => {
      persist = false;
    };
  }, [sourceUrl, revealUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const canvas = fabricRef.current;

    if (initialLoad) {
      setInitialLoad(false);

      if (!sourceImage && !revealImage) changeViewMode(VIEW_MODES.SLIDE);
      if (!sourceImage && revealImage) changeViewMode(VIEW_MODES.REVEAL);

      return;
    }

    if (canvas) {
      if (!sourceImage && !revealImage) {
        changeViewMode(VIEW_MODES.SLIDE);
      } else if (!revealImage && viewMode === VIEW_MODES.REVEAL) {
        changeViewMode(VIEW_MODES.SLIDE);
      } else if (revealImage && viewMode === VIEW_MODES.REVEAL) {
        changeViewMode(VIEW_MODES.REVEAL);
      } else if (viewMode === VIEW_MODES.SLIDE) {
        changeViewMode(VIEW_MODES.SLIDE);
      }
    }
  }, [sourceImage, revealImage, viewMode, changeViewMode, initialLoad]);

  useEffect(() => {
    const listener = () => {
      fabricRef.current!.resizeCanvas({
        height: containerRef.current!.clientHeight,
        width: containerRef.current!.clientWidth,
      });
    };

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.canvasContainer} ref={containerRef}>
        <canvas id="image-canvas" data-testid="image-canvas" />
        {containerRef.current && (
          <ActiveAreaLayer width={containerRef.current.clientWidth} height={containerRef.current.clientHeight} />
        )}
      </div>
      <div>
        <RadioGroup row name="viewMode" value={viewMode} onChange={e => changeViewMode(e.target.value)}>
          <FormControlLabel value={VIEW_MODES.SLIDE} control={<Radio />} label="Slide" labelPlacement="top" />
          <FormControlLabel
            disabled={!revealUrl}
            value={VIEW_MODES.REVEAL}
            control={<Radio />}
            label="Reveal"
            labelPlacement="top"
          />
        </RadioGroup>
      </div>
    </div>
  );
}
