import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { tss } from 'tss-react';
import { SlideEditorParams } from './utils';
import { ACTIVE_AREA_TYPE, ActiveAreaType, getActiveAreas, putActiveArea } from './api';
import DetailView from '../../components/layouts/detailview';

const hasErrors = (value: string) => {
  if (value.length === 0) return 'Name is required';
  if (value.length > 100) return 'Name is too long';
  return false;
};

const useStyles = tss.create(() => ({
  button: {
    color: 'white',
  },
}));

const ActiveAreaEditor = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<ActiveAreaType | null>(null);
  const params = useParams<SlideEditorParams>() as Required<SlideEditorParams>;

  useEffect(() => {
    const fetchActiveAreas = async () => {
      if (type) return;

      const { activeAreaId, ...rest } = params;
      const data = await getActiveAreas(rest);
      const area = data.find(a => a.id === Number(activeAreaId));

      if (area) {
        setName(area.name);
        setType(area.type);
      }
    };
    fetchActiveAreas();
  }, [type, params]);

  const handleSave = async () => {
    if (hasErrors(name) || !type) return;
    await putActiveArea({ ...params, name, type });
    navigate('..');
  };

  return (
    <DetailView
      title="Edit Active Area"
      previousPage=".."
      actionElement={
        <Button className={classes.button} variant="text" onClick={handleSave}>
          Save
        </Button>
      }
    >
      <Grid container margin="32px" marginTop="96px">
        <TextField
          required
          label="Active Area Name"
          variant="outlined"
          value={name || ''}
          error={Boolean(type) && Boolean(hasErrors(name))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
          helperText={hasErrors(name)}
        />
      </Grid>
      <Grid container margin="32px">
        <FormControl>
          <FormLabel id="active-area-type">Type:</FormLabel>
          <RadioGroup
            aria-labelledby="active-area-type"
            row
            name="50"
            value={type}
            onChange={e => setType(e.target.value as ActiveAreaType)}
          >
            <FormControlLabel value={ACTIVE_AREA_TYPE.REVEAL} control={<Radio />} label="Reveal" labelPlacement="end" />
            <FormControlLabel
              value={ACTIVE_AREA_TYPE.NAVIGATION}
              control={<Radio />}
              label="Slide Link"
              labelPlacement="end"
            />
            <FormControlLabel
              value={ACTIVE_AREA_TYPE.WEBLINK}
              control={<Radio />}
              label="Weblink"
              labelPlacement="end"
            />
            <FormControlLabel value={ACTIVE_AREA_TYPE.EMBED} control={<Radio />} label="Embed" labelPlacement="end" />
          </RadioGroup>
        </FormControl>
      </Grid>
    </DetailView>
  );
};

export default ActiveAreaEditor;
