import React, { useContext, useEffect, useRef } from 'react';
import { tss } from 'tss-react/mui';
import { useParams } from 'react-router';
import ActiveAreaService from '../services/ActiveAreaService';
import SlideEditorContext from '../context/SlideEditorContext';
import { Shape, addActiveArea } from '../api';
import { SlideEditorParams } from '../utils';

const useStyles = tss.create(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

type Props = {
  width: number;
  height: number;
};

export function ActiveAreaLayer({ width, height }: Props) {
  const { classes } = useStyles();
  const { activeAreas, isDrawing, fetchActiveAreas, setIsDrawing } = useContext(SlideEditorContext);
  const params = useParams<SlideEditorParams>() as Required<SlideEditorParams>;
  const activeAreaServiceRef = useRef<ActiveAreaService>();

  useEffect(() => {
    const service = new ActiveAreaService('active-area-canvas');
    service.initialize(width, height);

    activeAreaServiceRef.current = service;

    return () => {
      activeAreaServiceRef.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeAreaServiceRef.current) return;

    const service = activeAreaServiceRef.current;

    if (isDrawing) {
      const onAreaDrawn = async (shape: Shape) => {
        await addActiveArea(params, shape);
        setIsDrawing(false);
        await fetchActiveAreas();
      };

      service.startDrawingMode(onAreaDrawn);
    } else {
      service.stopDrawingMode();
      service.drawActiveAreas(activeAreas);
    }
  }, [activeAreaServiceRef, activeAreas, fetchActiveAreas, isDrawing, params, setIsDrawing]);

  return (
    <div className={classes.container}>
      <canvas id="active-area-canvas" data-testid="active-area-canvas" />
    </div>
  );
}
