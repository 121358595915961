import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  ChromeReaderMode as BookIcon,
  Store as StoreIcon,
} from '@mui/icons-material';

import { alpha } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import Account from '../account';
import PublishingHouseSelect from '../hierarchy/publishing-house-select-with-context';

const styles = theme => ({
  container: {
    flexGrow: 1,
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.drawerWidth,
  },
  appFrame: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerHeaderLeft: {
    flexGrow: 1,
  },
  drawerHeaderTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  drawerPaper: {
    position: 'relative',
    width: theme.drawerWidth,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 16,
  },
  publishingHouseContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    paddingLeft: theme.spacing(),
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: theme.spacing(2),
  },
  publishingHouseSelect: {
    color: 'inherit',
    marginLeft: 8,
    minWidth: 235,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -theme.drawerWidth + 12,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  hide: {
    display: 'none',
  },
});

export class Shell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpen: false,
    };
  }

  openDrawer() {
    this.setState({ isDrawerOpen: true });
  }

  closeDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  render() {
    const { classes, children, subTitle } = this.props;
    const { isDrawerOpen } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: isDrawerOpen,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => this.openDrawer()}
                className={classNames(classes.menuButton, isDrawerOpen && classes.hide)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.grow}>
                P Content Beheer
                {subTitle && ` (${subTitle})`}
              </Typography>
              <div className={classes.publishingHouseContainer}>
                <StoreIcon />
                <PublishingHouseSelect className={classes.publishingHouseSelect} />
              </div>
              <Account />
            </Toolbar>
          </AppBar>

          <Drawer
            id="drawer"
            variant="persistent"
            open={isDrawerOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <div className={classes.drawerHeaderLeft}>
                <Typography variant="h6" className={classes.drawerHeaderTitle}>
                  P-SHELL
                </Typography>
                <Typography variant="caption">
                  v &nbsp;
                  {VERSION}
                </Typography>
              </div>
              <IconButton onClick={() => this.closeDrawer()} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </div>

            <Divider />

            <List className={classes.menuList}>
              <ListItemButton component={NavLink} to="/" exact onClick={() => this.closeDrawer()}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>

              <ListItemButton component={NavLink} to="/codex" onClick={() => this.closeDrawer()}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="Digibooks" />
              </ListItemButton>
              <ListItemButton component={NavLink} to="/digislides" onClick={() => this.closeDrawer()}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="DigiSlides" />
              </ListItemButton>
            </List>
          </Drawer>

          <main
            className={classNames(classes.content, {
              [classes.contentShift]: isDrawerOpen,
            })}
          >
            {children}
          </main>
        </div>
      </div>
    );
  }
}

Shell.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  subTitle: PropTypes.string,
};

Shell.defaultProps = {
  classes: {},
  children: undefined,
  subTitle: undefined,
};

export default withStyles(styles)(Shell);
