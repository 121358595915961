import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, string, func, shape, number } from 'prop-types';

import { FormControl, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import MediaLinkTable from './media-link-table';
import TocSelector from './media-link-toc-selector';

import { getCurrentNodeForMediaContext } from '../../../../../selectors/codex-editor';
import { getMediaLinksForNodeId } from '../../../../../selectors/medialinks';
import { getTocNodesWithPageMapping } from '../../../../../selectors/table-of-content';
import { getCurrentHierarchyModuleId } from '../../../../../selectors/context';

import { setCurrentNodeForMediaContext } from '../../../../../actions/codex-editor';
import { fetchMediaLinksForNode } from '../../../../../actions/medialinks';

import tableOfContentConstants from '../../../../../constants/table-of-content';

export class AddMedialinkModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMedialinkIds: [],
    };
  }

  componentDidMount() {
    const { fetchMediaLinks, moduleId, currentNode } = this.props;

    if (currentNode) {
      fetchMediaLinks(moduleId, currentNode);
    }
  }

  handleTocChange = e => {
    const { onNodeChanged, moduleId, fetchMediaLinks, currentNode } = this.props;
    const newNode = e.target.value;

    if (newNode !== currentNode) {
      onNodeChanged(newNode);
      this.setState({ selectedMedialinkIds: [] });
      if (newNode) {
        fetchMediaLinks(moduleId, newNode);
      }
    }
  };

  handleSelectAllClick = event => {
    const { mediaLinksForNode } = this.props;
    if (event.target.checked) {
      this.setState({ selectedMedialinkIds: mediaLinksForNode.map(n => n.id) });
      return;
    }
    this.setState({ selectedMedialinkIds: [] });
  };

  handleMedialinkSelect = (event, id) => {
    const { selectedMedialinkIds } = this.state;
    const selectedIndex = selectedMedialinkIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMedialinkIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMedialinkIds.slice(1));
    } else if (selectedIndex === selectedMedialinkIds.length - 1) {
      newSelected = newSelected.concat(selectedMedialinkIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMedialinkIds.slice(0, selectedIndex),
        selectedMedialinkIds.slice(selectedIndex + 1),
      );
    }

    this.setState({ selectedMedialinkIds: newSelected });
  };

  isSelected = id => {
    const { selectedMedialinkIds } = this.state;
    return selectedMedialinkIds.indexOf(id) !== -1;
  };

  render() {
    const { handleClose, handleSave, mediaLinksForNode, tocNodes, linkAreaMediaLinks, currentNode } = this.props;
    const { selectedMedialinkIds } = this.state;
    return (
      <Dialog open onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">Add Media Link</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth>
            <TocSelector currentNode={currentNode} tocNodes={tocNodes} handleChange={this.handleTocChange} />
          </FormControl>
          <MediaLinkTable
            mediaLinks={mediaLinksForNode.filter(ml => !linkAreaMediaLinks.includes(ml.id))}
            handleSelection={this.handleMedialinkSelect}
            allSelected={selectedMedialinkIds.length > 0 && selectedMedialinkIds.length === mediaLinksForNode.length}
            handleSelectAll={this.handleSelectAllClick}
            isSelected={this.isSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSave(selectedMedialinkIds)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddMedialinkModal.propTypes = {
  linkAreaMediaLinks: arrayOf(string),
  mediaLinksForNode: arrayOf(
    shape({
      id: string,
      name: string,
      publishingState: string,
      contentType: string,
      hierarchy: arrayOf(string),
    }),
  ),
  tocNodes: arrayOf(
    shape({
      id: string,
      level: number,
      name: string,
    }),
  ),
  fetchMediaLinks: func.isRequired,
  handleClose: func.isRequired,
  handleSave: func.isRequired,
  onNodeChanged: func.isRequired,
  moduleId: string.isRequired,
  currentNode: string,
};

AddMedialinkModal.defaultProps = {
  linkAreaMediaLinks: [],
  mediaLinksForNode: [],
  tocNodes: [],
  currentNode: '',
};

const mapStateToProps = state => {
  const moduleId = getCurrentHierarchyModuleId(state);
  const currentNode = getCurrentNodeForMediaContext(state);
  const nodeId = currentNode && currentNode === tableOfContentConstants.GENERAL_NODE_ID ? moduleId : currentNode;
  return {
    mediaLinksForNode: getMediaLinksForNodeId(state, nodeId),
    moduleId,
    tocNodes: getTocNodesWithPageMapping(state, moduleId),
    currentNode,
  };
};

const mapDispatchToProps = {
  fetchMediaLinks: fetchMediaLinksForNode,
  onNodeChanged: setCurrentNodeForMediaContext,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMedialinkModal);
