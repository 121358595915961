import React, { useContext } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { format } from 'date-fns';
import { tss } from 'tss-react';
import { SlideSetVersion } from '../../../api/slideSetVersion';
import { capitalizeFirstLetter, getStateHistory } from '../../../utils/slideset-helper';
import ReviewAction from './ReviewAction';
import EditAction from './EditAction';
import DeleteAction from './DeleteAction';
import PublishAction from './PublishAction';
import ArchiveAction from './ArchiveAction';
import SlideSetVersionContext from '../../../contexts/slideset-version-context';
import CopyAction from './CopyAction';
import CopyToNewSlideSetAction from './CopyToNewSlideSetAction';
import PreviewAction from './PreviewAction';

const useStyles = tss.create(() => ({
  id: {
    width: '100px',
  },
  updated: {
    width: '200px',
  },
  state: {
    width: '200px',
  },
  actions: {
    width: '500px',
    span: {
      display: 'flex',
    },
  },
}));

type Props = {
  version: SlideSetVersion;
};

export const VersionRow = ({ version }: Props) => {
  const { getVersionIndex } = useContext(SlideSetVersionContext);
  const { classes } = useStyles();
  const index = getVersionIndex(version.id);

  return (
    <TableRow>
      <TableCell className={classes.id} data-testid={`${version.id}-identifier`}>
        {index}
      </TableCell>
      <TableCell className={classes.updated}>{format(new Date(version.updatedAt), 'dd-MM-yyyy HH:mm')}</TableCell>
      <TableCell className={classes.state}>{capitalizeFirstLetter(version.state)}</TableCell>
      <TableCell>{getStateHistory(version.stateHistory)}</TableCell>
      <TableCell className={classes.actions}>
        <EditAction version={version} />
        <CopyAction version={version} />
        <CopyToNewSlideSetAction version={version} />
        <PreviewAction version={version} />
        <ReviewAction version={version} />
        <PublishAction version={version} />
        <ArchiveAction version={version} />
        <DeleteAction version={version} />
      </TableCell>
    </TableRow>
  );
};

export default VersionRow;
