import React from 'react';
import { arrayOf, shape, string, bool, func } from 'prop-types';

import { Checkbox, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import MediaLinkRow from './media-link-row';

function MediaLinkTable({ mediaLinks, handleSelection, allSelected, handleSelectAll, isSelected, showHierarchy }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="dense">
            <Checkbox onChange={handleSelectAll} checked={allSelected} />
          </TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Contenttype</TableCell>
          {showHierarchy && <TableCell>TOC level</TableCell>}
          <TableCell>Publishing State</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mediaLinks &&
          mediaLinks.map(ml => (
            <MediaLinkRow
              key={ml.id}
              medialink={ml}
              isSelected={isSelected(ml.id)}
              medialinkSelected={handleSelection}
              showHierarchy={showHierarchy}
            />
          ))}
      </TableBody>
    </Table>
  );
}

MediaLinkTable.propTypes = {
  mediaLinks: arrayOf(
    shape({
      id: string,
      name: string,
      publishingState: string,
      contentType: string,
      hierarchy: arrayOf(string),
    }),
  ).isRequired,
  handleSelection: func.isRequired,
  allSelected: bool.isRequired,
  handleSelectAll: func.isRequired,
  isSelected: func.isRequired,
  showHierarchy: bool,
};

MediaLinkTable.defaultProps = {
  showHierarchy: false,
};

export default MediaLinkTable;
